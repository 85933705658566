import React from 'react'

import './Testimonial.scss'

function Testimonial() {
  return (
    <div>Testimonial</div>
  )
}

export default Testimonial