import React from 'react'

import './Skills.scss';

function Skills() {
  return (
    <div>Skills</div>
  )
}

export default Skills