import React from 'react'

import './Work.scss'

function Work() {
  return (
    <div>Work</div>
  )
}

export default Work