// import React from 'react';
// import ReactDOM  from 'react-dom';

// import App from './App';

// import './index.css'

// ReactDOM.render(<App/> ,document.getElementById('root'));
// ##https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis

import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);